<template>
    <div class="text-center m-0 p-0">
        <img src="../../../assets/img/notFound.svg" title="Sin resultados" />
        <h4> Sin resultados</h4>
    </div>
</template>

<style lang="sass" scoped>
img
    width: 100%
</style>