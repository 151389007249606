<template>
    <div class="aa-noticia-card mb-1">

        <div class="aa-noticia-card-body">
            <!-- <router-link :to="{name:url, params: {id:noticia.id}}"> -->
                <div class="image-card" :style="'background-image: url('+noticia.portada+')'" @click.self="ir(noticia.id)"></div> 
            <!-- </router-link> -->
            <div class="title-card">
            <router-link :to="{name:url, params: {id:noticia.id}}">
                {{noticia.titulo}}
            </router-link>
            </div>
        </div>
        <!-- <div class="aa-noticia-card-image">
            <router-link :to="{name:'noticia', params: {id:noticia.id}}">
            <img :src="noticia.portada" alt="">
            </router-link>
        </div>
        <div class="aa-noticia-card-text">
            <span class="aa-text-h3 text-start d-block text-uppercase ps-1 mt-1">
                {{noticia.titulo}}
            </span>
        </div> -->

    </div>
</template>

<script>
import { computed } from 'vue'
import { marked } from 'marked'
import { useRouter } from 'vue-router'

export default {
    props: {
        datos: {
            type: Object,
            require: true
        },
        pageLink: String
    },
    setup(props){

        const router = useRouter()

        const marcado = computed( () => {
            return marked(props.datos.cuerpo.slice(0,150) + '...')
        })

        const orderImg = computed( () => {
            return (props.datos.idx%2 === 0) ? 'order: 1' : 'order: 2'
        })

        const orderTxt = computed( () => {
            return (props.datos.idx%2 === 0) ? 'order: 2' : 'order: 1'
        })


        return {
            noticia: props.datos,
            marcado,
            orderImg,
            url: props.pageLink,
            orderTxt,
            ir: (id) => router.push({name:props.pageLink, params:{id}})
        }
    }
}
</script>

<style lang="sass" scoped>
.aa-noticia-card
    $root:&
    display: flex
    flex-wrap: wrap
    flex-direction: column
    &-body
        .image-card
            height: 150px
            overflow: hidden
            background-size: 100%
            background-repeat: no-repeat
            background-position: center
            border-radius: 9px 9px 0px 0px
            transition: all 0.3s linear
            &:hover
                cursor: pointer
                background-size: 110%
        .title-card
            flex: 1
            background-color: $base-color
            padding: 10px
            border-radius: 0px 0px 9px 9px
            min-height: 40px
            max-height: 40px
            text-align: left
            a
                color: $white
</style>
