<template class="aa-noticias-home">


  <div class="aa-noticias-home-titulo">
    <span class="aa-text-h1 text-start d-block text-uppercase p-1">
      Anuncios
    </span>
    
    <span class="aa-noticias-home-line"></span>
  </div>

  <template v-if="anuncios.length > 0">
  <div class="px-2">
    <swiper style="margin-right:30px"
      :slides-per-view="1"
      :loop="false"
      :space-between="20"
      :breakpoints="{
        '640': {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        '768': {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        '1024': {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      }"
      :modules="modules"
      class="noticias-slider"
    >
      <swiper-slide v-for="anuncio in anuncios" :key="anuncio.id">
        <noticia-card :datos="anuncio" :pageLink="'anuncio'" />
      </swiper-slide>

      
    </swiper>
  </div>
  </template>
  <template v-else>
    <NotFound />
  </template>

</template>

<script>
import { Swiper } from 'swiper/vue/swiper'
import { SwiperSlide } from 'swiper/vue/swiper-slide'
import { Pagination, Navigation } from "swiper"
import 'swiper/swiper.min.css'

import NoticiaCard from './NoticiaCard.vue'
import NotFound from '../NotFound.vue'
import useAnuncios from '../../composables/useAnuncios'



export default {
    components: { Swiper, SwiperSlide, NoticiaCard, NotFound },
    setup() {
      const { recientes } = useAnuncios()
      return {
        modules: [ Pagination, Navigation ],
        anuncios: recientes,
      };
    },
  };
</script>

<style lang="sass" scoped>


.aa-noticias-home
  $root:&

  &-titulo
    position: relative
    @include breakpoint(md)
      margin-inline-start: 30px
  &-line
    background-color: #86e4b9
    display: inline-block
    height: 25px
    left: 0px
    position: absolute
    top: 34px
    width: 172px
    z-index: -1

.noticias-slider 
  height: 320px

</style>